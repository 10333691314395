<template>
  <b-modal
    v-model="modalShow"
    id="booking-modal"
    title="BootstrapVue"
    hide-footer
  >
    <template #modal-title>Đăng ký phòng họp</template>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-form-group>
        <p class="mb-1 ml-3">Phòng họp:</p>
        <b-form-input
          class="form-control form-control-solid h-auto text-primary"
          id="example-input-1"
          name="example-input-1"
          :value="roomName"
          :disabled="true"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <p class="mb-1 ml-3">Tiêu đề cuộc họp:</p>
        <b-form-input
          class="form-control form-control-solid h-auto"
          type="text"
          id="example-input-2"
          name="example-input-2"
          v-model="$v.form.meetingTitle.$model"
          placeholder="Tiêu đề cuộc họp"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback class="pl-3">
          Tiêu đề cuộc họp không vượt quá 200 ký tự.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <p class="mb-1 ml-3">Nội dung cuộc họp:</p>
        <b-form-textarea
          class="form-control form-control-solid h-auto"
          type="text"
          id="example-input-2"
          name="example-input-2"
          v-model="$v.form.meetingContent.$model"
          placeholder="Tóm tắt nội dung cuộc họp"
          size="sm"
          :state="form.meetingContent?.length < 200"
        ></b-form-textarea>
        <b-form-invalid-feedback class="pl-3">
          Nội dung cuộc họp không vượt quá 200 ký tự.
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="row">
        <b-form-group class="col-6 pr-1">
          <p class="mb-1 ml-3">Ngày họp:</p>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="form-control form-control-solid h-auto"
            v-model="$v.form.selectedDate.$model"
            :options="dateSelectorOptions"
            size="sm"
            style="text-transform: capitalize"
            @change="defineHourSelectOptions()"
          ></b-form-select>

          <b-form-invalid-feedback>
            Vui lòng chọn ngày họp.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="col-6 pl-1">
          <p class="mb-1 ml-3">Phòng ban:</p>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="form-control form-control-solid h-auto"
            v-model="$v.form.departmentId.$model"
            :options="departmentListSelector"
            size="sm"
            aria-describedby="department-selector"
          ></b-form-select>

          <b-form-invalid-feedback id="department-selector">
            Vui lòng chọn phòng ban.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group class="col-6 pr-1">
          <p class="mb-1 ml-3">Bắt đầu:</p>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="form-control form-control-solid h-auto"
            v-model="$v.form.startingTime.$model"
            :options="startingHour"
            size="sm"
            @change="recurringMeeting = 'not_accepted'"
          ></b-form-select>

          <b-form-invalid-feedback>
            Vui lòng chọn thời gian bắt đầu.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="col-6 pl-1">
          <p class="mb-1 ml-3">Kết thúc:</p>
          <b-form-select
            id="inline-form-custom-select-pref"
            class="form-control form-control-solid h-auto"
            v-model="$v.form.endingTime.$model"
            :options="endingHour"
            size="sm"
            @change="recurringMeeting = 'not_accepted'"
          ></b-form-select>

          <b-form-invalid-feedback>
            Vui lòng chọn thời gian kết thúc.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group>
        <p class="d-flex align-items-center mb-1 ml-3">
          <span class="mr-1"> Thêm email đối tác:</span>
          <b-icon
            icon="question-circle"
            id="input-guest-email-lable"
            class="question-mark"
          ></b-icon>
          <b-tooltip
            target="input-guest-email-lable"
            triggers="hover"
            placement="right"
          >
            <span class="text-left">
              Đối với đối tác không có trong hệ thống, bạn có thể nhập email vào
              ô dưới đây để thêm vào danh sách tham dự cuộc họp cũng như gửi
              email thông báo.
              <br />
              <br />
              Tuy nhiên, <b class="text-primary">đối với những lần sau</b> hệ
              thống sẽ tự cập nhật thông tin người dùng và lưu vào ô đối tác ở
              phần lựa chọn ('Thêm người tham dự cuộc họp') bên dưới.
            </span>
          </b-tooltip>
        </p>
        <b-form-tags
          invalid-tag-text="Email không đúng định dạng vui lòng kiểm tra lại"
          :tag-validator="tagValidator"
          :state="state"
          input-id="tags-remove-on-delete"
          :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
          v-model="form.guestEmail"
          separator=" "
          :placeholder="form.guestEmail.length ? '' : 'Nhập email đối tác'"
          remove-on-delete
          duplicate-tag-text="Có 1 hoặc nhiều email đang bị trùng lặp"
          size="sm"
          input-class="guest-email-input"
          tag-class="email-tag"
          add-button-text="thêm"
        ></b-form-tags>
      </b-form-group>

      <input-send-email
        :payLoadFromInputSendEmail="payLoadFromInputSendEmail"
      />

      <div style="margin-top: -0.5rem">
        <b-form-checkbox
          v-model="recurringMeeting"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Đăng ký phòng họp định kỳ trong tháng
        </b-form-checkbox>
      </div>

      <div v-if="recurringValidated" class="mt-2">
        <div
          v-for="(day, index) in recurringValidated"
          :key="index"
          class="d-flex justify-content-between align-items-center mb-2 p-2 border border-secondary rounded"
        >
          <span
            :class="day.isConflict ? 'text-danger' : 'text-primary'"
            :style="
              !day.isSelected && !day.isConflict
                ? 'text-decoration: line-through black; text-decoration-thickness: 2px;'
                : ''
            "
          >
            <b class="text-dark" style="text-transform: capitalize">
              {{ day.formatedDay }}</b
            >,
            {{
              day.isConflict
                ? "khung giờ này đã có lịch họp."
                : "khung giờ này vẫn còn trống."
            }}
          </span>

          <b-icon
            v-if="index && !day.isConflict"
            :icon="
              !day.isSelected || day.isConflict
                ? 'x-square-fill'
                : 'check-square-fill'
            "
            :variant="!day.isSelected || day.isConflict ? 'danger' : 'primary'"
            role="button"
            disabled="true"
            @click="day.isSelected = !day.isSelected"
          ></b-icon>
        </div>
      </div>

      <div class="row mt-3">
        <div class="form-group col-6 pr-1">
          <b-button
            type="submit"
            class="w-100 font-weight-bold font-size-3"
            size="sm"
            variant="primary"
            ref="booking_meeting_room_submit"
          >
            Đăng ký
          </b-button>
        </div>

        <div class="form-group col-6 pl-1">
          <b-button
            type="button"
            size="sm"
            class="w-100 font-weight-bold font-size-3"
            @click="bookingAndSendEmail"
          >
            Đăng ký và gửi email
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { hourArray } from "@/core/mock/mock";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import moment from "moment";
import {
  errorNotification,
  successNotification,
} from "@/core/helpers/notification";
import ApiService from "@/core/services/api.service";
import { RELOAD_COUNT } from "@/core/services/store/store";
import InputSendEmail from "@/view/content/input/InputSendEmail.vue";
import { mapGetters } from "vuex";

export default {
  name: "booking-modal",
  props: ["roomInfo", "week"],
  mixins: [validationMixin],

  data() {
    return {
      form: {
        meetingContent: null,
        meetingTitle: null,
        selectedDate: null,
        startingTime: hourArray[0],
        endingTime: hourArray[1],
        departmentId: null,
        path: this.$route.name,
        guestEmail: [],
      },
      userArray: [],
      startingHour: hourArray,
      endingHour: hourArray,
      recurringMeeting: "not_accepted",
      recurringArray: [],
      recurringValidated: null,
      modalShow: false,
      dirty: false,
      emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
      hourArray,
    };
  },

  components: {
    InputSendEmail,
  },

  validations: {
    form: {
      meetingContent: {
        maxLength: maxLength(200),
      },
      meetingTitle: {
        maxLength: maxLength(200),
      },
      selectedDate: {
        // required,
      },
      startingTime: {
        required,
      },
      endingTime: {
        required,
      },
      departmentId: {
        // required,
      },
    },
  },

  methods: {
    tagValidator(tag) {
      // Individual tag validator function
      return this.emailRegex.test(tag);
    },
    resetForm() {
      this.form.meetingContent = "";
      this.form.meetingTitle = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit($event, isActiveSendEmail) {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const roomId = this.roomInfo.id;
      const meetingContent = this.$v.form.meetingContent.$model;
      const meetingTitle = this.$v.form.meetingTitle.$model;
      const selectedDate = this.$v.form.selectedDate.$model;
      let startingTime = this.$v.form.startingTime.$model;
      let endingTime = this.$v.form.endingTime.$model;
      const departmentId = this.$v.form.departmentId.$model;
      const userArray = this.userArray;
      const guestEmail = this.form.guestEmail;
      if (startingTime >= endingTime) {
        return errorNotification(
          "Thời gian bắt đầu không được lớn hơn hoặc bằng thời gian kết thúc cuộc họp."
        );
      }

      if (selectedDate == null) {
        return errorNotification("Vui lòng chọn ngày họp hoặc tuần họp khác.");
      }

      if (guestEmail.length) {
        const { data } = await ApiService.post("/bookingInfo/addGuestAccount", {
          guestEmail,
        });
        data.data.forEach((guest) => userArray.push(guest.id));
      }

      if (this.recurringMeeting === "accepted") {
        const meetingArray = [];

        this.recurringValidated.forEach((day) => {
          if (!day.isConflict && day.isSelected) {
            meetingArray.push({
              roomId,
              departmentId,
              meetingContent,
              meetingTitle,
              startingTime: `${day.day} ${startingTime}:00`,
              endingTime: `${day.day} ${endingTime}:00`,
            });
          }
        });

        await ApiService.post("/meeting/createRecurringMeeting", {
          roomId,
          recurringArray: meetingArray,
        })
          .then(async ({ data }) => {
            const { startingTime, endingTime, meetingContent } = data.data[0];
            this.$bvModal.hide("booking-modal");
            this.$store.dispatch(RELOAD_COUNT);
            userArray.push(this.currentUser.id);

            await ApiService.post("/meeting/addParticipantsToMeeting", {
              meetingArray: data.data.map((meeting) => meeting.id),
              userArray,
            });

            const bookingIdList = await data.data.map((item) => item.id);

            const payloadCreateEventCalendar = {
              meetingContent,
              meetingTitle,
              endTime: new Date(endingTime).toISOString(),
              startTime: new Date(startingTime).toISOString(),
              userArray,
              bookingId: bookingIdList,
              recurringMeeting: this.recurringMeeting,
            };

            await ApiService.post(
              "/bookinginfo/bookingMeetingToGoogleCalendar",
              payloadCreateEventCalendar
            );

            if (!isActiveSendEmail) {
              successNotification("Đăng ký phòng họp định kỳ thành công");
            } else {
              successNotification(
                "Đăng ký phòng họp định kỳ thành công.\nNhưng hiện chưa thể gửi mail cho tính năng đăng ký định kỳ.\nVui lòng gửi mail ở trang danh sách cuộc họp."
              );
            }
          })
          .catch(({ response }) => {
            this.defineRecurringValidated();
            if (response && response.data) {
              errorNotification(response.data.message);
            }
          });

        return;
      }

      startingTime = `${selectedDate} ${startingTime}:00`;
      endingTime = `${selectedDate} ${endingTime}:00`;

      const bookingInfo = {
        roomId,
        meetingContent,
        startingTime,
        endingTime,
        departmentId,
        meetingTitle,
      };

      await ApiService.post("/meeting/bookingRoom", bookingInfo)
        .then(async (db) => {
          const { id } = db.data.data;
          userArray.push(this.currentUser.id);

          const payload = {
            userArray,
            bookingId: id,
            userIdLogin: this.currentUser.id,
            user: this.currentUser.email,
            roomId,
          };

          successNotification("Đăng ký phòng họp thành công");
          this.$bvModal.hide("booking-modal");
          this.$store.dispatch(RELOAD_COUNT);

          const response = await ApiService.post(
            "/meeting/addParticipantsToMeeting",
            { meetingArray: [id], userArray }
          );

          if (response.status === 200) {
            await ApiService.post("/newMeetingNotify", { bookingId: id });
          }

          if (isActiveSendEmail) {
            await this.sendEmail(payload, userArray, id);
          }

          const payloadCreateEventCalendar = {
            meetingContent,
            meetingTitle,
            endTime: new Date(endingTime).toISOString(),
            startTime: new Date(startingTime).toISOString(),
            userArray,
            bookingId: id,
          };

          await ApiService.post(
            "/bookinginfo/bookingMeetingToGoogleCalendar",
            payloadCreateEventCalendar
          );
        })
        .catch(({ response }) => {
          if (response.data) {
            return errorNotification(response.data.message);
          }
        });
    },
    bookingAndSendEmail() {
      this.onSubmit(event, true);
    },
    payLoadFromInputSendEmail(db) {
      this.userArray = db;
    },
    defineHourSelectOptions() {
      const today = moment(new Date()).format("YYYY-MM-DD");
      const now = moment(new Date()).format("HH:mm");
      this.recurringMeeting = "not_accepted";
      this.startingHour = this.startingOptions;
      this.endingHour = this.endingOptions;

      if (this.form.selectedDate !== today) {
        return;
      }
      if (this.form.selectedDate === today) {
        const newStartingHour = [];
        const newEndingHour = [];
        this.startingHour.forEach((hour, index) => {
          const startingOption = {
            text: hour,
            value: hour,
          };
          const endingOption = {
            text: this.endingHour[index],
            value: this.endingHour[index],
          };

          if (hour < now) {
            newStartingHour.push({ ...startingOption, disabled: true });
            newEndingHour.push({ ...endingOption, disabled: true });
          } else {
            newStartingHour.push({ ...startingOption, disabled: false });
            newEndingHour.push({ ...endingOption, disabled: false });
          }
        });

        this.startingHour = newStartingHour;
        this.endingHour = newEndingHour;

        const validHourIndex = newStartingHour.findIndex(
          (item) => !item.disabled
        );
        if (validHourIndex === -1) {
          return;
        } else {
          this.form.startingTime = newStartingHour[validHourIndex].value;
          this.form.endingTime = newEndingHour[validHourIndex].value;
        }
      }
    },
    defineDefaultOptions() {
      if (!this.departmentListSelector.length) return;

      if (!this.currentUser.departmentId) return;
      const departmentIndex = this.departmentListSelector.findIndex(
        (department) => department.value === this.currentUser.departmentId
      );

      this.$v.form.departmentId.$model =
        this.departmentListSelector[departmentIndex].value;
    },
    async sendEmail(payload, userArray, id) {
      await ApiService.post("/bookinginfo/sendEmail", payload).then(() => {
        ApiService.put("/bookinginfo/checkSendEmail", {
          userArray,
          bookingId: id,
        }).then(() => {
          this.userArray = [];
        });
      });
    },
    async defineRecurringValidated() {
      const { data: conflict } = await ApiService.post(
        "/meeting/recurringMeetingValidation",
        {
          roomId: this.roomInfo.id,
          recurringArray: this.recurringArray,
        }
      );

      this.recurringValidated = this.recurringArray.map((day) => {
        const chekedDay = moment(day.startingTime).format("YYYY-MM-DD");
        const formatedDay = moment(day.startingTime).format("dddd, DD/MM/YYYY");
        const isConflict = conflict.data.day.findIndex(
          (conflictDay) => conflictDay === chekedDay
        );

        if (isConflict !== -1)
          return {
            day: chekedDay,
            formatedDay,
            isConflict: true,
            isSelected: false,
          };
        if (isConflict === -1)
          return {
            day: chekedDay,
            formatedDay,
            isConflict: false,
            isSelected: true,
          };
      });
    },
  },

  computed: {
    ...mapGetters(["currentUser"]),
    roomName() {
      if (this.roomInfo) return this.roomInfo.roomName;
      return "";
    },
    ["roomInfo.id"]() {
      if (this.roomInfo) return this.roomInfo.id;
      return "";
    },
    dateSelectorOptions() {
      const today = moment(new Date()).format("YYYY-MM-DD");
      return this.week.map((date, index) => {
        const options = {};
        const optionsValue = moment(date).format("YYYY-MM-DD");
        options.text = moment(date).format("dddd, DD/MM/YYYY");
        options.value = optionsValue;
        if (today > optionsValue || index === 6) {
          options.disabled = true;
        }
        return options;
      });
    },
    departmentListSelector() {
      return this.$store.getters.departmentList.map((item) => {
        return { text: item.departmentName, value: item.id };
      });
    },
    startingOptions() {
      const startingHour = [...hourArray];
      startingHour.pop();
      return startingHour;
    },
    endingOptions() {
      const endingHour = [...hourArray];
      endingHour.shift();
      return endingHour;
    },
    state() {
      // Overall component validation state
      return this.dirty ? this.dirty.error : null;
    },
  },

  watch: {
    dateSelectorOptions: {
      immediate: true,
      handler(newDateSelectorOptions) {
        const today = moment(new Date()).format("YYYY-MM-DD");
        const dateOptionLength = newDateSelectorOptions.length;
        const lastOption = newDateSelectorOptions[dateOptionLength - 1];
        for (let i = 0; i < dateOptionLength; i++) {
          if (newDateSelectorOptions[i].value >= today) {
            this.$v.form.selectedDate.$model = newDateSelectorOptions[i].value;
            this.defineHourSelectOptions();
            return;
          }
          if (lastOption.value <= today) {
            lastOption.text = "Vui lòng chọn tuần họp khác.";
            lastOption.value = null;
            this.$v.form.selectedDate.$model = null;
            return;
          }
        }
      },
    },
    departmentListSelector: {
      immediate: true,
      handler() {
        this.defineDefaultOptions();
      },
    },
    recurringMeeting: {
      async handler(recurringMeeting) {
        if (recurringMeeting === "not_accepted") {
          this.recurringArray = [];
          this.recurringValidated = null;
          return;
        }

        const selectedDate = new Date(this.$v.form.selectedDate.$model);
        let firstDayOfNextMonth = null;
        if (selectedDate.getMonth() == 11) {
          firstDayOfNextMonth = new Date(selectedDate.getFullYear() + 1, 0, 1);
        } else {
          firstDayOfNextMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            1
          );
        }

        for (let i = 0; i < 7; i++) {
          const nextWeek = new Date(
            selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000 * i
          );

          if (nextWeek > firstDayOfNextMonth) break;

          const nextWeekFormat = moment(nextWeek).format("YYYY-MM-DD");

          let startingTime = this.$v.form.startingTime.$model;
          let endingTime = this.$v.form.endingTime.$model;
          startingTime = `${nextWeekFormat} ${startingTime}:00`;
          endingTime = `${nextWeekFormat} ${endingTime}:00`;

          this.recurringArray.push({ startingTime, endingTime });
        }

        await this.defineRecurringValidated();
      },
    },
    modalShow: {
      immediate: true,
      handler(modalShow) {
        if (modalShow) {
          this.resetForm();
          this.defineDefaultOptions();
          this.recurringMeeting = "not_accepted";
          this.form.guestEmail = [];
          this.payLoadFromInputSendEmail([]);
        }
      },
    },
    tags() {
      this.dirty = true;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modal-header {
  padding: 1rem 2rem;
}
.modal-body {
  padding: 1rem;
}
.form-group {
  margin-bottom: 1rem;
}
.li-h-35 li {
  height: 35px !important;
  min-height: 0px;
}
.question-mark {
  cursor: pointer;
}
.question-mark:hover {
  color: #027bff;
}
</style>

<style>
.text-truncate {
  display: block;
  margin: auto;
  padding: 2px 3px;
}
.email-tag {
  display: flex !important;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  margin: 2px;
  padding: 0 0.5rem;
}
.guest-email-input {
  padding: 0;
}
.b-form-tags {
  padding: 0.555rem;
}
.tooltip-inner {
  text-align: left;
}
</style>
