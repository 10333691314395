<template>
  <div>
    <b-modal
      :id="`booking-detail-modal-${bookingID}`"
      title="BootstrapVue"
      hide-footer
    >
      <template #modal-title>Thông tin chi tiết lịch họp</template>
      <div class="row">
        <p class="col-6 mb-2">
          <span>Phòng họp:</span> {{ bookingInfo.roomName }}
        </p>
        <p
          class="col-6 mb-2"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <span>Người tạo:</span> {{ bookingInfo.fullname }}
        </p>
      </div>

      <div class="row">
        <p class="col-6 mb-2" style="text-transform: capitalize">
          <span>Ngày:</span> {{ time.day }}
        </p>
        <p class="col-6 mb-2"><span>Bắt đầu:</span> {{ time.start }}</p>
      </div>

      <div class="row">
        <p class="col-6 mb-2">
          <span>Thời gian tham dự:</span> {{ attendenceTime }}
        </p>
        <p class="col-6 mb-2"><span>Kết thúc:</span> {{ time.end }}</p>
      </div>

      <br />

      <p style="white-space: pre-line">
        <span>Tiêu đề cuộc họp:</span>
        <br />
        {{
          bookingInfo.meetingTitle
            ? bookingInfo.meetingTitle
            : "Không có tiêu đề cuộc họp"
        }}
        <br />
        <span>Nội dung cuộc họp:</span>
        <br />
        {{
          bookingInfo.meetingContent
            ? bookingInfo.meetingContent
            : "Không có nội dung cuộc họp"
        }}
      </p>

      <br />

      <p v-if="cancelAllowed">
        <b-button
          size="sm"
          v-b-modal:[`cancel-booking-modal-${bookingID}`]
          class="w-100 d-flex justify-content-between align-items-center"
          variant="danger"
          @click="$bvModal.hide(`booking-detail-modal-${bookingID}`)"
        >
          <b class="mr-2" style="font-size: 1.25rem">Huỷ lịch họp</b>
          <i class="fa fa-ban p-0"></i>
        </b-button>
      </p>
    </b-modal>
    <cancel-booking-modal
      :bookingID="bookingID"
      :bookingInfo="bookingInfo"
      :role="role"
    />
  </div>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CancelBookingModal from "@/view/content/modals/CancelBookingModal.vue";
import { timeDefine } from "@/core/helpers/timeHandling";
import { mapGetters } from "vuex";

export default {
  name: "booking-detail-modal",
  props: ["bookingID", "bookingInfo", "timeRange"],
  mixins: [validationMixin],

  data() {
    return {
      cancelAllowed: false,
      form: {
        cancelReason: "",
      },
      role: null,
    };
  },

  validations: {
    form: {
      cancelReason: {
        required,
      },
    },
  },

  components: {
    CancelBookingModal,
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        cancelReason: "",
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },

  async created() {
    const { role, id } = this.currentUser;
    const { userId, endingTime } = this.bookingInfo;
    const isDone = timeDefine(null, endingTime).isDone();

    if ((userId === id && !isDone) || (role === "admin" && !isDone)) {
      this.cancelAllowed = true;
    }

    if (userId !== id && role === "admin") {
      this.role = role;
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),
    attendenceTime() {
      const hours = Math.trunc((this.timeRange * 30) / 60);
      const minutes = (this.timeRange * 30) % 60;
      if (hours < 1) return `${minutes} phút`;
      if (minutes === 0) return `${hours} giờ`;
      return `${hours} giờ ${minutes} phút`;
    },
    time() {
      const { startingTime, endingTime } = this.bookingInfo;
      return {
        day: moment(startingTime).format("dddd, DD/MM/YYYY"),
        start: moment(startingTime).format("HH:mm"),
        end: moment(endingTime).format("HH:mm"),
      };
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 600;
  color: #429cff;
}
</style>
