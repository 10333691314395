<template>
  <div style="margin-top: -10px">
    <ul class="d-flex p-0" style="margin-bottom: 20px; list-style: none">
      <router-link
        :to="`/${room.id}/${room.roomLink}`"
        v-slot="{ href, navigate, isExactActive }"
        v-for="room in roomList"
        :key="room.id"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="room-change-button"
          :class="[isExactActive && 'room-change-button-active']"
        >
          <a
            :href="href"
            @click="navigate"
            style="display: inline-block; padding: 0.65rem 1.1rem"
          >
            <span>{{ room.roomName }}</span>
          </a>
        </li>
      </router-link>
    </ul>

    <div class="meeting-room-container">
      <div>
        <div class="justify-item-center p-5 mb-5 bg-white rounded">
          <div class="h-100 d-flex flex-column justify-content-between">
            <h3>{{ roomInfo && roomInfo.roomName }}</h3>
            <p>Số lượng chỗ ngồi: {{ roomInfo && roomInfo.seats }}</p>

            <div>
              <b-button size="sm" v-b-modal.booking-modal class="w-100">
                Đăng ký phòng họp
              </b-button>
              <booking-modal :roomInfo="roomInfo" :week="week" />
            </div>
          </div>
        </div>

        <div>
          <v-calendar
            :attributes="attributes"
            @dayclick="onDayClick"
            class="w-100"
          />
        </div>
      </div>

      <div>
        <booking-table
          v-if="bookingList"
          :dateArray="dateArray"
          :bookingList="bookingList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { removeAscent } from "@/core/helpers/stringHandling";
import { defineWeekRangeForVCalendar } from "@/core/helpers/calendarHandling";
import BookingTable from "@/view/content/bookingtable/BookingTable.vue";
import BookingModal from "@/view/content/modals/BookingModal.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { loading } from "@/core/helpers/loadingHandling";

export default {
  name: "meetingRoom",
  data() {
    return {
      roomList: [],
      roomInfo: null,
      attributes: [
        {
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: {
            start: new Date(),
            end: new Date(),
          },
        },
      ],
      week: [],
      dateArray: [],
      bookingList: null,
      monday: null,
    };
  },
  components: {
    BookingTable,
    BookingModal,
  },
  methods: {
    onDayClick(day) {
      const { date } = day;
      const { week } = defineWeekRangeForVCalendar(date);
      this.setDatesRange(week);
    },
    setDatesRange(week) {
      const monday = week[0];
      const sunday = week[week.length - 1];
      this.attributes[0].dates.start = monday;
      this.attributes[0].dates.end = sunday;

      if (
        moment(this.monday).format("YYYY-MM-DD") !==
        moment(monday).format("YYYY-MM-DD")
      ) {
        this.week = week;
        this.monday = monday;
        moment.locale("vi");
        this.dateArray = week.map((day) => {
          return {
            day: moment(day).format("dddd"),
            ["DD/MM"]: moment(day).format("DD/MM"),
            ["YYYY-MM-DD"]: moment(day).format("YYYY-MM-DD"),
          };
        });
      }
    },
    async getBookingList(roomId) {
      roomId *= 1;
      await ApiService.post("/meeting/getBookingInfoByRoomIDAndDate", {
        roomId,
        week: [
          `${this.dateArray[0]["YYYY-MM-DD"]} 00:00:00`,
          `${this.dateArray[this.dateArray.length - 1]["YYYY-MM-DD"]} 23:59:59`,
        ],
      }).then(({ data: bookingList }) => {
        this.bookingList = bookingList.data;
      });
    },
  },

  computed: {
    ...mapGetters(["reloadCount"]),
  },

  watch: {
    "$route.params.roomID": {
      immediate: true,
      async handler(roomId) {
        const { data: roomInfo } = await ApiService.get("/room", roomId);
        this.roomInfo = roomInfo.data;
        this.getBookingList(roomId);
      },
    },
    monday: {
      handler() {
        this.getBookingList(this.$route.params.roomID);
      },
    },
    reloadCount: {
      handler() {
        this.getBookingList(this.$route.params.roomID);
      },
    },
    bookingList: {
      immediate: true,
      handler(bookingList) {
        if (bookingList === null) {
          loading.on({ background: "white" });
          return;
        }
        if (bookingList !== null) {
          loading.off({ delay: 500 });
        }
      },
    },
  },

  async created() {
    const { week } = defineWeekRangeForVCalendar(new Date());
    this.setDatesRange(week);

    const { data } = await ApiService.get("/room/getList");
    const roomList = data.data.map((item) => {
      item.roomLink = removeAscent(item.roomName.replace(/ /g, ""));
      return item;
    });

    this.roomList = roomList;
  },
};
</script>

<style scoped>
.room-change-button {
  margin-right: 5px;
  border-radius: 4px;
  background-color: white;
  color: #6c7293;
  cursor: pointer;
  transition: 0.3s;
}
.room-change-button:hover {
  /* background-color: #3699ff; */
  background-color: #aeafb5;
  color: white;
}
.room-change-button-active {
  background-color: #bd1e2d !important;
  color: white !important;
}
.room-change-button a {
  color: inherit;
}

.meeting-room-container {
  display: grid;
  grid-template-columns: 250px calc(100% - 250px);
  column-gap: 20px;
  overflow: hidden;
}
</style>