<template>
  <div class="table-booking">
    <div class="d-flex">
      <div class="d-flex rounded position-relative">
        <ul
          class="hour-col p-0 mb-0 text-center"
          style="margin-top: -30px; z-index: 3"
        >
          <li class="hour"></li>
          <li
            v-for="(hour, index) in hourArray"
            :key="index"
            class="hour d-flex align-items-center"
          >
            <span class="p-1 rounded"> {{ hour }}</span>
          </li>
        </ul>

        <div>
          <ul class="week sticky-top d-flex p-0 m-0">
            <li
              v-for="(date, index) in week"
              :key="index"
              :class="`booking-table-box border text-center ${
                date.clickable ? 'day text-white' : 'bg-light'
              } rounded`"
              :style="`cursor:${
                date.clickable ? 'pointer' : 'not-allowed'
              }; text-transform: capitalize;`"
              @click="openModal(date.clickable)"
            >
              <p v-if="date.today" class="mb-0" style="text-transform: none">
                Hôm nay
                <br />
                {{ date["DD/MM"] }}
              </p>
              <p v-else class="m-0">
                {{ date.day }}
                <br />
                {{ date["DD/MM"] }}
              </p>
            </li>
          </ul>

          <ul class="week d-flex p-0 m-0">
            <li
              v-for="(date, idx) in week"
              :key="idx"
              class="booking-table-box"
              :id="date['YYYY-MM-DD'] + '-' + $route.params.roomID"
            >
              <ul class="w-100 p-0">
                <li
                  v-for="(hour, index) in hourArray"
                  v-show="hour !== '18:00'"
                  :key="index"
                  :id="`${date['YYYY-MM-DD']} ${hour}:00 room-${$route.params.roomID}`"
                  :ref="`${date['YYYY-MM-DD']} ${hour}:00 room-${$route.params.roomID}`"
                  class="border bg-white position-relative"
                >
                  <booking-tag
                    v-if="booking(`${date['YYYY-MM-DD']} ${hour}:00`)"
                    :booking="booking(`${date['YYYY-MM-DD']} ${hour}:00`)"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BookingTag from "@/view/content/bookingtable/BookingTag.vue";
import { hourArray } from "@/core/mock/mock.js";
import { timeDefine } from "@/core/helpers/timeHandling";

export default {
  name: "booking-table",
  props: ["dateArray", "bookingList"],
  data() {
    return {
      hourArray,
    };
  },

  computed: {
    week() {
      const today = moment(new Date()).format("YYYY-MM-DD");
      return this.dateArray.map((date, index) => {
        if (date["YYYY-MM-DD"] < today || index === this.dateArray.length - 1) {
          date.clickable = false;
        } else {
          date.clickable = true;
        }
        if (date["YYYY-MM-DD"] === today) {
          date.today = true;
        }
        return date;
      });
    },
    booking() {
      return (bookingDate) => {
        const bookingInfo = this.bookingList.find(
          (item) =>
            moment(item.startingTime).format("YYYY-MM-DD HH:mm:ss") ===
            bookingDate
        );

        if (bookingInfo) {
          const { startingTime, endingTime } = bookingInfo;
          const { defineTimeRange } = timeDefine(startingTime, endingTime);
          return { bookingInfo, timeRange: defineTimeRange() };
        }
        return;
      };
    },
  },

  components: {
    BookingTag,
  },

  methods: {
    openModal(clickable) {
      if (clickable) {
        this.$bvModal.show("booking-modal");
      }
    },
  },
};
</script>

<style scoped>
li {
  min-height: 60px;
  list-style: none;
}

.hour {
  min-width: 45px;
  min-height: 60px;
  box-sizing: border-box;
  background-color: #eef0f8;
}

.hour-col {
  position: sticky;
  left: 0;
}

.container-booking {
  overflow: scroll;
}

.table-booking {
  max-height: 660px;
  overflow: scroll;
}

.week {
  width: 1400px;
  z-index: 2;
}

.booking-table-box {
  width: 200px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.day {
  background-color: #c82334 !important;
  transition: 0.2s;
}

.day:hover {
  /* background-color: #1888fa !important; */
  background-color: #b20b1c !important;
  transform: scale(1.05);
  z-index: 2;
}
</style>