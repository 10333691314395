function defineWeekRangeForVCalendar(pickedDate) {
  const daySelected = pickedDate.getDay();
  const monday = new Date(pickedDate.getTime());

  if (daySelected === 0) {
    monday.setDate(monday.getDate() - 6);
  } else {
    monday.setDate(monday.getDate() - (daySelected - 1));
  }

  let day = new Date(monday.getTime());
  let week = [monday];
  let i = 0;

  while (i < 6) {
    day.setDate(day.getDate() + 1);
    week.push(new Date(day));
    i++;
  }

  return { monday, week };
}

export { defineWeekRangeForVCalendar };
