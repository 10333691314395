<template>
  <div>
    <div
      :id="`booking-tag-${booking.bookingInfo.id}`"
      class="booking-tag-container p-2 border rounded text-light"
      :style="`
      height:${booking.timeRange * 60}px; 
      background-color:${
        bookingInfo.status === 'done' ? '#72777b' : bookingInfo.departmentColor
      }`"
      v-b-modal:[`booking-detail-modal-${booking.bookingInfo.id}`]
    >
      <span>
        <b> Nội dung cuộc họp:</b>
        <br />
        {{ meetingContent }}
        <br />
        <br />
        <b>Phòng ban:</b> {{ bookingInfo.departmentName }}
        <br />
        <b> Trạng thái:</b> {{ status }}
      </span>
    </div>
    <booking-detail-modal
      :bookingID="booking.bookingInfo.id"
      :bookingInfo="booking.bookingInfo"
      :timeRange="booking.timeRange"
    />
    <b-tooltip
      :target="`booking-tag-${booking.bookingInfo.id}`"
      triggers="hover"
      placement="right"
    >
      <span style="white-space: pre-line">
        <b> Nội dung cuộc họp:</b>
        <br />
        {{ meetingContent }}
        <br />
        <br />
        <b>Phòng ban:</b> {{ bookingInfo.departmentName }}
        <br />
        <b> Trạng thái:</b> {{ status }}
      </span>
    </b-tooltip>
  </div>
</template>

<script>
import BookingDetailModal from "@/view/content/modals/BookingDetailModal.vue";
import { getMaxCharOfStr } from "@/core/helpers/stringHandling";
import { timeDefine } from "@/core/helpers/timeHandling";

export default {
  name: "booking-tag",
  props: ["booking"],

  components: {
    BookingDetailModal,
  },
  methods: {
    deletedUserOutOfMeeting() {},
  },

  computed: {
    bookingInfo() {
      const { startingTime, endingTime } = this.booking.bookingInfo;
      const { isHappening, isDone } = timeDefine(startingTime, endingTime);
      let cloneBookingInfo = { ...this.booking.bookingInfo };

      if (isHappening()) cloneBookingInfo.status = "areMeeting";
      if (isDone()) cloneBookingInfo.status = "done";
      return cloneBookingInfo;
    },
    meetingContent() {
      const { meetingContent } = this.booking.bookingInfo;

      return meetingContent
        ? getMaxCharOfStr(meetingContent, 50)
        : "Không có nội dung cuộc họp";
    },

    status() {
      const { status } = this.bookingInfo;
      if (status === "done") return "Đã kết thúc";
      if (status === "areMeeting") return "Đang diễn ra";
      return "Sắp diễn ra";
    },
  },
};
</script>

<style scope>
.booking-tag-container {
  width: 100%;
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  white-space: pre-line;
}
.custom-btn-delete {
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 40px;
  height: 26px;
}
.custom-btn-delete i {
  position: absolute;
  left: 12px;
  top: 4px;
}

.tooltip {
  opacity: 1;
  z-index: 1;
}
</style>
