import moment from "moment";
import { hourArray } from "@/core/mock/mock.js";

function timeDefine(startingTime, endingTime) {
  const now = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");
  const start = moment(startingTime).format("YYYY/MM/DD HH:mm:ss");
  const end = moment(endingTime).format("YYYY/MM/DD HH:mm:ss");

  const startTimeOnly = moment(startingTime).format("HH:mm");
  const endTimeOnly = moment(endingTime).format("HH:mm");

  function isHappening() {
    if (start <= now && now < end) return true;
    return false;
  }
  function isDone() {
    if (end < now) return true;
    return false;
  }
  function defineTimeRange() {
    const startIndex = hourArray.findIndex((time) => time === startTimeOnly);
    const endingIndex = hourArray.findIndex((time) => time === endTimeOnly);
    return endingIndex - startIndex;
  }

  return { isHappening, isDone, defineTimeRange };
}

export { timeDefine };
